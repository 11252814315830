"use client";

import clsx from "clsx";
import { Link, useMatch } from "react-router-dom";
import { CargoElement, CargoElementProps } from "../../CargoElement";
import { Badge } from "../../Foundations";
import { IconCheckmarkCircle, IconCircleOutlined } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import { toToString } from "./StepperUtils";
import { Step as StepType } from "./step.model";
import styles from "./Step.module.scss";

export interface StepProps extends Omit<CargoElementProps, "children"> {
  hideBadge?: boolean;
  hideLine?: boolean;
  step: StepType;
}

const IncompleteIcon = <Icon iconComponent={IconCircleOutlined} />;
const CompleteIcon = <Icon iconComponent={IconCheckmarkCircle} />;

export function Step({ className, hideBadge, hideLine, step, ...props }: StepProps) {
  const match = useMatch({ end: false, path: toToString(step.to) });

  const isActive = !!match;
  return (
    <CargoElement
      className={clsx(
        styles.container,
        {
          [styles.isComplete]: step.isComplete,
          [styles.isActive]: isActive
        },
        className
      )}
      {...props}
    >
      <div className={styles.badgeAndLine}>
        {!hideBadge && (
          <Badge
            Icon={step.isComplete ? CompleteIcon : IncompleteIcon}
            label="step icon"
            noLabel
            className={styles.badge}
          />
        )}
        <div
          className={clsx(styles.verticalLine, {
            [styles.noLine]: hideLine
          })}
        />
      </div>
      <div className={styles.content}>
        {step.children?.length && isActive ? (
          <>
            <Link to={step.to}>{step.Component}</Link>
            {step.children.map((childStep, childStepIndex) => (
              <Step key={childStepIndex} hideBadge hideLine step={childStep} />
            ))}
          </>
        ) : (
          <Link to={step.to}>{step.Component}</Link>
        )}
      </div>
    </CargoElement>
  );
}
