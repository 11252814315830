import { useContext, useEffect } from "react";
import { Location, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserAccount } from "api/Account.model";
import * as invitesAPI from "api/Invites";
import { UserInvite } from "api/User.model";
import * as usersAPI from "api/Users";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";
import { universalErrorHandlerToString } from "helpers/errorMessages";
import { loadFromLocalStorage } from "helpers/localStorage";
import { retryRequest, retryRequestIf } from "helpers/retryRequest";

function getBestUserAccountID(
  userID: string,
  userAccounts: UserAccount[],
  location: Location
): string {
  // 1. Check value stored in location state
  const locationState = (location.state as any) || {};
  if (locationState.activeUserAccountID) return locationState.activeUserAccountID;
  // 2. Check value in local storage
  const lasActiveAccountsByUser = loadFromLocalStorage("moovAccounts", {}, "object");
  const mostRecentUserAccountID = lasActiveAccountsByUser[userID];
  if (mostRecentUserAccountID === "moov-admin") return "moov-admin";
  if (userAccounts.some((ua) => ua.accountID === mostRecentUserAccountID))
    return mostRecentUserAccountID;
  return userAccounts[0].accountID;
}

export function WithActiveAccountID() {
  const { user, setUserAccounts, setInvites, activeUserAccountID, setActiveUserAccountID } =
    useContext(UserContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const navigate = useNavigate();
  const currentLocation = useLocation();

  useEffect(() => {
    const selectAccount = async (): Promise<void> => {
      // Load any invites and the user's accounts at the same time. User
      // accounts sometimes take longer to load, so we retry a few times
      let [userAccounts, userInvites] = await Promise.all<
        [Promise<UserAccount[]>, Promise<UserInvite[]>]
      >([
        retryRequestIf(
          () => usersAPI.listUserAccounts(user!.userID),
          (result) => !result || result.length === 0
        ),
        usersAPI.listUserInvites(user!.userID)
      ]);

      const hasAccounts = userAccounts && userAccounts.length > 0;
      const hasInvites = userInvites && userInvites.length > 0;

      // Case 1: User has no accounts and no invites
      if (!hasAccounts && !hasInvites) {
        navigate("/create-account");
        return;
      }

      let bestUserAccountID: string;

      // Case 2: User has no accounts but at least one invite
      if (!hasAccounts && hasInvites) {
        // Accept the invite immediately
        const invite = userInvites[0];
        await retryRequest(() => invitesAPI.acceptInvite(invite.accountID, invite.inviteID));
        userInvites = userInvites.slice(1);

        userAccounts = await retryRequestIf(
          () => usersAPI.listUserAccounts(user!.userID),
          (result) => !result?.length
        );

        bestUserAccountID = invite.accountID;
      }

      // Case 3: User has at least one account
      else {
        bestUserAccountID = getBestUserAccountID(user!.userID, userAccounts, currentLocation);
      }

      // Activate the account
      setInvites(userInvites || []);
      setUserAccounts(userAccounts);
      setActiveUserAccountID(bestUserAccountID);

      if (bestUserAccountID === "moov-admin" && currentLocation.pathname === "/") {
        setTimeout(() => navigate("/admin/accounts", { replace: true }), 0);
      }
    };

    if (user && !activeUserAccountID) {
      selectAccount().catch((err: Response) => {
        toast(universalErrorHandlerToString(err));
      });
    }

    // We only want this to trigger when the user changes:
  }, [user]);

  const isAdminMode = activeUserAccountID === "moov-admin";
  let canRender = !!user && !!activeUserAccountID && !!facilitatorID;
  if (isAdminMode) canRender = !!user && !!activeUserAccountID;

  return canRender ? <Outlet /> : null;
}
