import { useContext } from "react";
import { ValidatedInput, type ValidatedInputProps } from "@moovfinancial/cargo";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Account, Business, Individual, Name } from "api/v2";
import { capitalizeString } from "helpers/capitalizeWords";
import { AccountSetupContext } from "../../AccountSetupContext";
import { ActionType } from "../../AccountSetupReducer";

interface AccountInputProps extends ValidatedInputProps {
  name: keyof Business | keyof Individual | keyof Account | keyof Name;
  showDefaultWarningMessage?: boolean;
  dispatchType?: ActionType;
  label: string;
}

export const AccountInput = ({
  name,
  value,
  placeholder,
  label = "",
  showDefaultWarningMessage,
  error,
  isLocked,
  dispatchType,
  required,
  ...rest
}: AccountInputProps) => {
  const {
    dispatch,
    account: { accountType },
    isErrored,
    invalidInputs,
    resetInput,
    validate
  } = useContext(AccountSetupContext);

  const getWarningMessage = () => `${capitalizeString(label.toLowerCase())} is missing or invalid.`;

  const getErrorMessage = () => {
    if (error) return error;
    if (invalidInputs.includes(name)) {
      return required && !value ? "This field is required" : "This field is invalid.";
    }
    return `${capitalizeString(label.toLowerCase())} is invalid.`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetInput(name);
    dispatch({
      type: dispatchType ?? accountType,
      value: { [name]: e.target.value }
    });
  };

  return (
    <ValidatedInput
      warning={showDefaultWarningMessage && getWarningMessage()}
      error={(isErrored(name) || invalidInputs.includes(name)) && getErrorMessage()}
      disabled={isLocked}
      isLocked={isLocked}
      label={label}
      name={name}
      pattern={REGEX.NO_ILLEGAL_CHARACTERS}
      placeholder={placeholder}
      required={required}
      value={value}
      onBlur={(e) => {
        validate(e.target);
      }}
      onChange={handleChange}
      noMargins={false}
      {...rest}
    />
  );
};
