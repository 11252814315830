import _ from "lodash";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { To, matchPath, useLocation, useParams } from "react-router-dom";
import { Step } from "@moovfinancial/cargo/src/components/Complex/Stepper/step.model";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import {
  Account,
  BankAccount,
  FileResponse,
  OnboardingContext,
  Representative,
  Underwriting
} from "./OnboardingContext";
import { OnboardingInviteContext } from "./OnboardingInviteContext";

export type Invite = components["schemas"]["OnboardingInvite"];
type StepNavigation = Readonly<Record<string, { next: To; previous: To }>>;

interface OnboardingStepsContextProps {
  children: React.ReactNode;
}

interface OnboardingSteps {
  getNextStepUrl: () => To;
  getPreviousStepUrl: () => To;
  hasExpectedActivityStep: () => boolean;
  hasFulfillmentStep: () => boolean;
  hasOwnersStep: () => boolean;
  isComplete: boolean;
  steps: Step[];
}

const FINISH_STEP_PATH_REGEX = new RegExp(/\/finish/);
const isAtFinishStep = (location: string) => FINISH_STEP_PATH_REGEX.test(location);

export const OnboardingStepsContext = createContext<OnboardingSteps>({
  getNextStepUrl: () => "/",
  getPreviousStepUrl: () => "/",
  hasExpectedActivityStep: () => false,
  hasFulfillmentStep: () => false,
  hasOwnersStep: () => false,
  isComplete: false,
  steps: []
});

const isBankAccountsComplete = (bankAccounts: BankAccount[]) => bankAccounts.length > 0;

const isBusinessComplete = (account: Partial<Account>) => {
  const business = account?.profile?.business;
  return (
    !!business &&
    !!business.legalBusinessName &&
    !!business.businessType &&
    !!business.email &&
    !!business.website &&
    !!business.phone?.number &&
    !!business.taxIDProvided &&
    !!business.industryCodes?.sic &&
    !!business.address?.addressLine1
  );
};

const isProcessingStatementsComplete = (files: FileResponse[] | undefined) => {
  return (
    !!files &&
    // TODO: Allow for files to not be uploaded if the user clicked the checkbox certifying that they don't have any files
    files.length > 0
  );
};

const isRepresentativeComplete = (representative: Representative) =>
  representative &&
  representative.name?.firstName &&
  representative.name?.lastName &&
  representative.email &&
  representative.responsibilities &&
  representative.address?.addressLine1 &&
  representative.birthDateProvided &&
  representative.governmentIDProvided &&
  representative.phone?.number;

const isRepresentativesComplete = (representatives: Representative[]) =>
  representatives.length > 0 && representatives.every(isRepresentativeComplete);

const isUnderwritingComplete = (
  account: Partial<Account>,
  underwriting: Underwriting | undefined
) =>
  !!account.profile?.business?.description &&
  !!underwriting &&
  !!underwriting.averageMonthlyTransactionVolume &&
  !!underwriting.averageTransactionSize &&
  !!underwriting.maxTransactionSize;

const areBusinessCapabilities = (capabilities: Invite["capabilities"]) =>
  _.intersection(capabilities, ["collect-funds", "send-funds", "card-issuing", "wallet"]).length >
  0;

const getStepNavigation = (token: string, capabilities: Invite["capabilities"]): StepNavigation =>
  ({
    [`${getOnboardingPath(token)}/business`]: {
      next: `${getOnboardingPath(token)}/business/information`,
      previous: `${getOnboardingPath(token)}`
    },
    [`${getOnboardingPath(token)}/business/information`]: {
      next: `${getOnboardingPath(token)}/business/address`,
      previous: `${getOnboardingPath(token)}/business`
    },
    [`${getOnboardingPath(token)}/business/address`]: {
      next: areBusinessCapabilities(capabilities)
        ? `${getOnboardingPath(token)}/owners`
        : `${getOnboardingPath(token)}/bank-account`,
      previous: `${getOnboardingPath(token)}/business/information`
    },
    [`${getOnboardingPath(token)}/owners`]: {
      next: `${getOnboardingPath(token)}/expected-activity/transactions`,
      previous: `${getOnboardingPath(token)}/business/address`
    },
    [`${getOnboardingPath(token)}/expected-activity/transactions`]: {
      next: capabilities.includes("collect-funds")
        ? `${getOnboardingPath(token)}/expected-activity/fulfillment`
        : `${getOnboardingPath(token)}/expected-activity/processing-statements`,
      previous: `${getOnboardingPath(token)}/owners`
    },
    [`${getOnboardingPath(token)}/expected-activity/fulfillment`]: {
      next: `${getOnboardingPath(token)}/expected-activity/processing-statements`,
      previous: `${getOnboardingPath(token)}/expected-activity/transactions`
    },
    [`${getOnboardingPath(token)}/expected-activity/processing-statements`]: {
      next: `${getOnboardingPath(token)}/bank-account`,
      previous: capabilities.includes("collect-funds")
        ? `${getOnboardingPath(token)}/expected-activity/fulfillment`
        : `${getOnboardingPath(token)}/expected-activity/transactions`
    },
    [`${getOnboardingPath(token)}/bank-account`]: {
      next: `${getOnboardingPath(token)}/review`,
      previous: areBusinessCapabilities(capabilities)
        ? `${getOnboardingPath(token)}/expected-activity/processing-statements`
        : `${getOnboardingPath(token)}/business/address`
    },
    [`${getOnboardingPath(token)}/review`]: {
      next: `${getOnboardingPath(token)}/finish`,
      previous: `${getOnboardingPath(token)}/bank-account`
    }
  }) as const;

export const getOnboardingPath = (token: string | undefined = "") =>
  `/onboarding/${token}` as const;

function findCurrentStep(steps: Step[], location: string) {
  let currentStep: Step | undefined;
  steps.forEach((step) => {
    // set the end flag for steps that have no children, but do have child routes (e.g. /owners)
    if (matchPath({ path: step.to.toString(), end: !!step.children?.length }, location)) {
      currentStep = step;
    } else if (step.children) {
      const childStep = findCurrentStep(step.children, location);
      if (childStep) {
        currentStep = childStep;
      }
    }
  });
  return currentStep;
}

export function OnboardingStepsContextProvider({ children }: OnboardingStepsContextProps) {
  const { invite } = useContext(OnboardingInviteContext);
  const { account, bankAccounts, files, representatives, underwriting } =
    useContext(OnboardingContext);
  const currentLocation = useLocation();
  const { token } = useParams();
  const [steps, setSteps] = useState<Step[]>([]);
  const [stepNavigation, setStepNavigation] = useState<StepNavigation>({});

  const getCurrentStepNavigation = useCallback(() => {
    if (!steps) return;
    const currentStep = findCurrentStep(steps, currentLocation.pathname);
    if (!currentStep) return;
    return stepNavigation[currentStep.to.toString()];
  }, [currentLocation.pathname, stepNavigation, steps]);

  const getNextStepUrl = useCallback(
    () => getCurrentStepNavigation()?.next ?? getOnboardingPath(token),
    [getCurrentStepNavigation, token]
  );

  const getPreviousStepUrl = useCallback(
    () => getCurrentStepNavigation()?.previous ?? getOnboardingPath(token),
    [getCurrentStepNavigation, token]
  );

  const hasOwnersStep = useCallback(
    () => areBusinessCapabilities(invite?.capabilities ?? []),
    [invite?.capabilities]
  );

  const hasExpectedActivityStep = useCallback(
    () => areBusinessCapabilities(invite?.capabilities ?? []),
    [invite?.capabilities]
  );

  const hasFulfillmentStep = useCallback(
    () => (invite?.capabilities ?? []).includes("collect-funds"),
    [invite?.capabilities]
  );

  const areAllStepsComplete = useCallback(
    (
      account: Partial<Account>,
      bankAccounts: BankAccount[],
      files: FileResponse[] | undefined,
      representatives: Representative[],
      underwriting: Underwriting | undefined
    ) => {
      return (
        isBankAccountsComplete(bankAccounts) &&
        isBusinessComplete(account) &&
        (hasExpectedActivityStep() ? isProcessingStatementsComplete(files) : true) &&
        (hasOwnersStep() ? isRepresentativesComplete(representatives) : true) &&
        (hasExpectedActivityStep() ? isUnderwritingComplete(account, underwriting) : true)
      );
    },
    [hasExpectedActivityStep, hasOwnersStep]
  );

  useEffect(() => {
    if (token) {
      setStepNavigation(getStepNavigation(token, invite?.capabilities ?? []));
    }
  }, [invite, token]);

  useEffect(() => {
    const settableSteps: Step[] = [
      {
        Component: "Business",
        to: `${getOnboardingPath(token)}/business`,
        children: [
          {
            Component: "Business information",
            to: `${getOnboardingPath(token)}/business/information`
          },
          { Component: "Business address", to: `${getOnboardingPath(token)}/business/address` }
        ],
        isComplete: isBusinessComplete(account)
      }
    ];

    if (invite?.capabilities) {
      // determine which steps to show based on capabilities
      if (hasOwnersStep()) {
        // @TODO future - remove step for industry type that is financial institution
        settableSteps.push({
          Component: "Owners & officers",
          isComplete: isRepresentativesComplete(representatives),
          to: `${getOnboardingPath(token)}/owners`
        });

        const expectedActivityStep: Step = {
          Component: "Expected activity",
          to: `${getOnboardingPath(token)}/expected-activity`,
          children: [
            {
              Component: "Transactions",
              to: `${getOnboardingPath(token)}/expected-activity/transactions`
            },
            {
              Component: "Processing statements",
              to: `${getOnboardingPath(token)}/expected-activity/processing-statements`
            }
          ],
          isComplete:
            isUnderwritingComplete(account, underwriting) && isProcessingStatementsComplete(files)
        };
        if (hasFulfillmentStep()) {
          const fulfillmentStep = {
            Component: "Fulfillment",
            to: `${getOnboardingPath(token)}/expected-activity/fulfillment`
          };
          // insert fulfillment step within expected activity
          expectedActivityStep.children!.splice(1, 0, fulfillmentStep);
        }
        settableSteps.push(expectedActivityStep);
      }
    }
    // Bank account & review step are always included
    settableSteps.push(
      {
        Component: "Bank account",
        isComplete: isBankAccountsComplete(bankAccounts),
        to: `${getOnboardingPath(token)}/bank-account`
      },
      {
        Component: "Review & finish",
        isComplete: isAtFinishStep(currentLocation.pathname),
        to: `${getOnboardingPath(token)}/review`
      }
    );

    // replacing the entire array of steps. we don't expect capabilities to change once we get the invite
    setSteps(settableSteps);
  }, [
    account,
    bankAccounts,
    files,
    hasFulfillmentStep,
    hasOwnersStep,
    invite?.capabilities,
    currentLocation.pathname,
    representatives,
    token,
    underwriting
  ]);

  const isComplete = useMemo(
    () => areAllStepsComplete(account, bankAccounts, files, representatives, underwriting),
    [account, areAllStepsComplete, bankAccounts, files, representatives, underwriting]
  );

  const value = useMemo<OnboardingSteps>(
    () => ({
      getNextStepUrl,
      getPreviousStepUrl,
      hasExpectedActivityStep,
      hasFulfillmentStep,
      hasOwnersStep,
      isComplete,
      steps
    }),
    [
      getNextStepUrl,
      getPreviousStepUrl,
      hasExpectedActivityStep,
      hasFulfillmentStep,
      hasOwnersStep,
      isComplete,
      steps
    ]
  );

  return (
    <OnboardingStepsContext.Provider value={value}>{children}</OnboardingStepsContext.Provider>
  );
}
