import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Container from "components/container/Container";

function FourOhFour() {
  const currentLocation = useLocation();
  return (
    <Container>
      <Helmet>
        <title>404 Not found</title>
      </Helmet>
      <h1>404</h1>
      <p>Page could not be found: "{currentLocation.pathname}"</p>
    </Container>
  );
}

export default FourOhFour;
