import { useCallback, useMemo } from "react";
import {
  BirthDate,
  IncompleteBirthDate,
  birthDateToDate,
  birthDateToFormattedString,
  dateToBirthDate,
  formattedDateStringToBirthDate,
  isBirthDate
} from "@moovfinancial/common/utils/format/formatDate";
import {
  DateMaskingInput,
  type DateMaskingInputProps
} from "../../../../Form/Input/MaskingInputs/DateMaskingInput";

export type { BirthDate, IncompleteBirthDate } from "@moovfinancial/common/utils/format/formatDate";

export type BirthDateMaskingInputProps = Omit<DateMaskingInputProps, "onValueChange" | "value"> & {
  /**
   * function called on value change. The function should update the value in the parent component's state.
   */
  onValueChange?: (value: BirthDate | IncompleteBirthDate, formattedValue: string) => void;
  /**
   * The BirthDate or IncompleteBirthDate value of the input
   */
  value: BirthDate | IncompleteBirthDate | undefined;
};

export function BirthDateMaskingInput({
  onValueChange,
  value,
  ...props
}: BirthDateMaskingInputProps) {
  const valueDateOrString = useMemo(() => {
    if (!value) {
      return "";
    }
    if (isBirthDate(value)) {
      return birthDateToDate(value);
    }
    return birthDateToFormattedString(value);
  }, [value]);

  const handleOnValueChange = useCallback(
    (value: Date | undefined, formattedValue: string) => {
      if (!value) {
        onValueChange?.(formattedDateStringToBirthDate(formattedValue), formattedValue);
        return;
      }
      onValueChange?.(dateToBirthDate(value), formattedValue);
    },
    [onValueChange]
  );

  return (
    <DateMaskingInput onValueChange={handleOnValueChange} value={valueDateOrString} {...props} />
  );
}
