import maybeMockWithPrism from "helpers/mockWithPrism";
import { AccountsAPI, LiveAccountsAPI } from "./accounts";
import { AdminAPI, LiveAdminAPI } from "./admin";
import { AnalyticsAPI, LiveAnalyticsAPI } from "./analytics";
import { ApiKeysAPI, LiveApiKeysAPI } from "./apiKeys";
import { AvatarsAPI, LiveAvatarsAPI } from "./avatars";
import { CapabilitiesAPI, LiveCapabilitiesAPI } from "./capabilities";
import { DisputesAPI, LiveDisputesAPI } from "./disputes";
import { IssuingAPI, LiveIssuingAPI } from "./issuing";
import { LiveLookbackAPI, LookbackAPI } from "./lookback";
import { LiveMoovFeeConfigAPI, MoovFeeConfigAPI } from "./moovFeeConfig";
import { LiveNotificationsAPI, NotificationsAPI } from "./notifications";
import { LivePaymentMethodsAPI, PaymentMethodsAPI } from "./paymentMethods/paymentMethods";
import { LiveProfileEnrichmentAPI, ProfileEnrichmentAPI } from "./profileEnrichment";
import { ErrorResponse, RequestOptions, request } from "./request";
import { LiveTransfersAPI, TransfersAPI } from "./transfers";
import { LiveTransfersConfigAPI, TransfersConfigAPI } from "./transfersConfig";
import { LiveUnderwritingAPI, UnderwritingAPI } from "./underwriting";
import { LiveUsersAPI, UsersAPI } from "./users";
import { LiveWalletAPI, WalletAPI } from "./wallets";

export interface MoovFetchOptions extends RequestOptions {
  /** ID to send in the X-Account-ID header. */
  xAccountID?: string;
}

export interface MoovAPIClient {
  accounts: AccountsAPI;
  admin: AdminAPI;
  analytics: AnalyticsAPI;
  apiKeys: ApiKeysAPI;
  avatars: AvatarsAPI;
  capabilities: CapabilitiesAPI;
  disputes: DisputesAPI;
  issuing: IssuingAPI;
  notifications: NotificationsAPI;
  moovFeeConfig: MoovFeeConfigAPI;
  paymentMethods: PaymentMethodsAPI;
  profileEnrichment: ProfileEnrichmentAPI;
  transfers: TransfersAPI;
  transfersConfig: TransfersConfigAPI;
  underwriting: UnderwritingAPI;
  users: UsersAPI;
  wallets: WalletAPI;
  lookback: LookbackAPI;
}

export interface LiveMoovAPIClientOptions {
  /** URL to prepend to all requests. */
  baseURL?: string;
  /** Injectable fetch function. Defaults to the global fetch function. */
  fetchFn?: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response>;
}

export class LiveMoovAPIClient implements MoovAPIClient {
  private _baseURL: string;
  private _fetchFn: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response>;

  accounts: AccountsAPI;
  admin: AdminAPI;
  analytics: AnalyticsAPI;
  apiKeys: ApiKeysAPI;
  avatars: AvatarsAPI;
  capabilities: CapabilitiesAPI;
  disputes: DisputesAPI;
  issuing: IssuingAPI;
  notifications: NotificationsAPI;
  moovFeeConfig: MoovFeeConfigAPI;
  paymentMethods: PaymentMethodsAPI;
  profileEnrichment: ProfileEnrichmentAPI;
  transfers: TransfersAPI;
  transfersConfig: TransfersConfigAPI;
  underwriting: UnderwritingAPI;
  users: UsersAPI;
  wallets: WalletAPI;
  lookback: LookbackAPI;

  constructor({ baseURL, fetchFn }: LiveMoovAPIClientOptions = {}) {
    this._baseURL = baseURL || "";
    this._fetchFn = fetchFn || window.fetch;

    this.accounts = new LiveAccountsAPI(this);
    this.admin = new LiveAdminAPI(this);
    this.analytics = new LiveAnalyticsAPI(this);
    this.apiKeys = new LiveApiKeysAPI(this);
    this.avatars = new LiveAvatarsAPI(this);
    this.capabilities = new LiveCapabilitiesAPI(this);
    this.disputes = new LiveDisputesAPI(this);
    this.issuing = new LiveIssuingAPI(this);
    this.moovFeeConfig = new LiveMoovFeeConfigAPI(this);
    this.notifications = new LiveNotificationsAPI(this);
    this.paymentMethods = new LivePaymentMethodsAPI(this);
    this.profileEnrichment = new LiveProfileEnrichmentAPI(this);
    this.transfers = new LiveTransfersAPI(this);
    this.transfersConfig = new LiveTransfersConfigAPI(this);
    this.underwriting = new LiveUnderwritingAPI(this);
    this.users = new LiveUsersAPI(this);
    this.wallets = new LiveWalletAPI(this);
    this.lookback = new LiveLookbackAPI(this);
  }

  async request<T>(
    url: string,
    options?: MoovFetchOptions
  ): Promise<[T | undefined, ErrorResponse | undefined, Response | undefined]> {
    // Get full request url
    const fullUrl = maybeMockWithPrism(this._baseURL, url);

    // Remove undefined query params
    if (options && options.query) {
      Object.keys(options.query).forEach((key) =>
        options.query[key] === undefined ? delete options.query[key] : {}
      );
    }

    // Add x-account-id header
    if (options?.xAccountID) {
      options.headers = {
        ...options.headers,
        "X-Account-ID": options.xAccountID
      };
    }
    return request<T>(fullUrl, options, this._fetchFn);
  }
}
