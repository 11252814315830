import { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { toToString } from "./StepperUtils";
import { Step } from "./step.model";
import styles from "./CurrentStep.module.scss";

interface CurrentStepProps {
  steps: Step[];
}

export function CurrentStep({ steps }: CurrentStepProps) {
  const currentLocation = useLocation();
  const [step, setStep] = useState<Step>();
  const [subStep, setSubStep] = useState<Step>();
  const [pageNumber, setPageNumber] = useState(1);

  const findRouteMatch = useCallback(() => {
    let match;
    let subMatch;
    const pathname = currentLocation.pathname.split("/");
    pathname.pop();
    const parentPathname = pathname.join("/");

    // TODO: The Stepper can have infinite children, but this function is only checking the first two levels
    steps.forEach((currentStep) => {
      const isMatch = matchPath(currentLocation.pathname, toToString(currentStep.to));
      if (isMatch) {
        match = currentStep;
      } else if (currentStep.children?.length) {
        currentStep.children.forEach((subStep) => {
          const isMatch = matchPath(currentLocation.pathname, toToString(subStep.to));
          if (isMatch) {
            match = currentStep;
            subMatch = subStep;
          }
        });
      } else {
        const isMatch = matchPath(parentPathname, toToString(currentStep.to));
        if (isMatch) {
          match = currentStep;
        }
      }
    });
    setStep(match);
    setSubStep(subMatch);
  }, [currentLocation.pathname, steps]);

  useEffect(() => {
    if (step) {
      setPageNumber(steps.indexOf(step) + 1);
    } else {
      // Step not found
      setPageNumber(0);
    }
  }, [step, steps]);

  useEffect(() => {
    findRouteMatch();
  }, [findRouteMatch, currentLocation]);

  // If we're not on a known step, don't render the component
  return !pageNumber ? null : (
    <nav className={styles.container}>
      <progress value={pageNumber} max={steps.length} className={styles.progress} />
      <div className={styles.pages}>
        Step {pageNumber} of {steps.length}
      </div>
      <div className={styles.stepName}>
        {/* TODO: if step.Component isn't a string this may be to style */}
        {step?.Component}{" "}
        {subStep && `– ${typeof subStep.Component === "string" ? subStep.Component : ""}`}
      </div>
    </nav>
  );
}
