import { useContext, useState } from "react";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { AccountInput } from "../components/AccountInput";
import { AddressField } from "../components/AddressField";
import { ManualAddress } from "../components/ManualAddress";
import { PhoneField } from "../components/PhoneField";
import LegacyAlert from "components/alert/Alert";
import { Step } from "components/dialog/StepModal";
import Toaster from "components/toaster/Toaster";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../AccountSetupContext";
import useAccountModalCalls from "../../useAccountModalCalls";
import styles from "./IndividualContact.module.scss";

export const IndividualContact = () => {
  const {
    account,
    manualAddress,
    errorMessages,
    capabilityRequirements,
    invalidInputs,
    setInvalidInputs
  } = useContext(AccountSetupContext);
  const { createAccount, updateAccount, loading } = useAccountModalCalls();

  const { accountLocks } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);
  const [warnPhoneOrEmail, setWarnPhoneOrEmail] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [alertPhoneOrEmail, setAlertPhoneOrEmail] = useState(false);

  if (manualAddress) {
    return <ManualAddress setAddressUpdated={setAddressUpdated} />;
  }

  const handleBlur = () => {
    if (!account.profile?.individual?.email && !account.profile?.individual?.phone?.number) {
      setWarnPhoneOrEmail(true);
    } else {
      setWarnPhoneOrEmail(false);
    }
  };

  const handleSubmit = () => {
    let canContinue = true;
    if (!account.profile?.individual?.name.firstName) {
      setInvalidInputs((prev) => [...prev, "firstName"]);
      canContinue = false;
    }
    if (!account.profile?.individual?.name.lastName) {
      setInvalidInputs((prev) => [...prev, "lastName"]);
      canContinue = false;
    }
    if (
      (!account.profile?.individual?.phone?.number || invalidInputs.includes("phone")) &&
      (!account.profile?.individual?.email || invalidInputs.includes("email"))
    ) {
      setAlertPhoneOrEmail(true);
      canContinue = false;
    }

    if (canContinue) {
      account.accountID ? updateAccount() : createAccount();
    }
  };

  return (
    <Step className={styles.individualContact} onSubmit={handleSubmit} loading={loading} noValidate>
      {errorMessages && <Toaster toastInput={errorMessages} duration={4000} />}
      <Text textStyle="paragraph-m-regular">
        All fields, unless labeled optional, are required to create an account and enable
        capabilities. However some information can be provided later.
      </Text>
      <div>
        <PhoneField
          warn={!!accountRequirements?.profile?.individual?.phone?.number}
          isLocked={!!accountLocks?.profile?.individual?.phone?.number}
          dispatchType="individualPhone"
          value={account.profile?.individual?.phone?.number || ""}
          onBlur={handleBlur}
        />
        <AccountInput
          label="Email"
          name="email"
          type="email"
          value={account.profile?.individual?.email || ""}
          disabled={!!accountLocks?.profile?.individual?.email}
          isLocked={!!accountLocks?.profile?.individual?.email}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.email}
          error={warnPhoneOrEmail ? "Phone or email is required" : undefined}
          onBlur={handleBlur}
        />
        <AddressField
          address={account.profile?.individual?.address}
          warn={
            !!accountRequirements?.profile?.individual?.address?.addressLine1 && !addressUpdated
          }
          isLocked={!!accountLocks?.profile?.individual?.address?.addressLine1}
          onBlur={handleBlur}
        />
        {alertPhoneOrEmail && (
          <LegacyAlert className={styles.alert} type="warning">
            An email or a phone number is required.
          </LegacyAlert>
        )}
      </div>
    </Step>
  );
};
