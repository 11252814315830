import clsx from "clsx";
import {
  type ChangeEvent,
  CompositionEvent,
  type FocusEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AddressAutoComplete,
  type AddressSuggestion,
  Alert,
  Button,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  type GovernmentID,
  GovernmentIDInput,
  PercentInput,
  PhoneNumberInput,
  Select
} from "@moovfinancial/cargo";
import {
  BirthDate,
  BirthDateMaskingInput,
  IncompleteBirthDate
} from "@moovfinancial/cargo/src/components/BusinessLogicCoupled/Form/Input/MaskingInputs/BirthDateMaskingInput";
import useValidatedFields, { FieldMeta } from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import {
  armedForcesOptions,
  stateOptions,
  territoryOptions
} from "@moovfinancial/common/constants/state";
import { isBirthDate } from "@moovfinancial/common/utils/format/formatDate";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Checkbox } from "components/form/Form";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { OnboardingContext, Representative } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import { OwnersStepHeading } from "./OwnersStepHeading";
import { RemoveOwnerConfirmation } from "./RemoveOwnerConfirmation";
import styles from "./AddOwner.module.scss";

// Default error message
const ERROR_MESSAGE = "This field is required";

/**
 *
 * @TODO: In an ideal world, fields would be a 1:1 reflection of the form state, but we can't do that bc some inputs
 * take / give back complex data types instead of strings / numbers
 *
 * We should fix this later
 *
 * */

type ValidatedFields =
  | "firstName"
  | "lastName"
  | "ownershipPercentage"
  | "jobTitle"
  | "email"
  | "phone"
  | "birthDate"
  | "ssn"
  | "itin";

const fieldsToValidate: Record<ValidatedFields, Partial<FieldMeta>> = {
  firstName: { message: "A valid first name is required" },
  lastName: { message: "A valid last name is required" },
  ownershipPercentage: {},
  jobTitle: {},
  email: {},
  phone: {},
  birthDate: {},
  ssn: {},
  itin: {}
};
interface FormState {
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
  };
  birthDate: BirthDate | undefined;
  birthDateProvided: boolean;
  email: string;
  firstName: string;
  governmentID?: GovernmentID;
  governmentIDProvided: boolean;
  isController: boolean;
  isOwner: boolean;
  jobTitle: string;
  lastName: string;
  ownershipPercentage: number;
  phone?: string;
  representativeID?: string;
}

const mapRepresentativeToFormState = (representative: Representative | undefined): FormState => ({
  address: representative?.address ?? {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "US",
    postalCode: "",
    stateOrProvince: ""
  },
  birthDate: representative?.birthDate ?? undefined,
  birthDateProvided: representative?.birthDateProvided ?? false,
  email: representative?.email ?? "",
  firstName: representative?.name?.firstName ?? "",
  governmentID: representative?.governmentID ?? undefined,
  governmentIDProvided: representative?.governmentIDProvided ?? false,
  isController: representative?.responsibilities?.isController ?? false,
  isOwner: representative?.responsibilities?.isOwner ?? false,
  jobTitle: representative?.responsibilities?.jobTitle ?? "",
  lastName: representative?.name?.lastName ?? "",
  ownershipPercentage: representative?.responsibilities?.ownershipPercentage ?? 0,
  phone: representative?.phone?.number,
  representativeID: representative?.representativeID
});

const authorizedRepresentativeFields: Partial<Record<keyof FormState, boolean>> = {
  firstName: true,
  lastName: true,
  isOwner: true,
  isController: true,
  representativeID: true
};

const isOwnerFields: Record<keyof FormState, boolean> = {
  address: true,
  birthDate: true,
  birthDateProvided: true,
  email: true,
  firstName: true,
  governmentID: true,
  governmentIDProvided: true,
  isController: true,
  isOwner: false,
  jobTitle: true,
  lastName: true,
  ownershipPercentage: true,
  phone: true,
  representativeID: true
};

const isControllerFields: Record<keyof FormState, boolean> = {
  address: true,
  birthDate: true,
  birthDateProvided: true,
  email: true,
  firstName: true,
  governmentID: true,
  governmentIDProvided: true,
  isController: true,
  // if we send this field as false when controller = true, API fails
  isOwner: false,
  jobTitle: true,
  lastName: true,
  ownershipPercentage: true,
  phone: true,
  representativeID: true
};

const mapFormStateToRepresentative = (formState: FormState): Partial<Representative> => {
  const isController = formState.isController;
  const isOwner = formState.isOwner;

  const shouldSendField = (fieldName: keyof FormState) => {
    const sendFieldBecauseController = isController && isControllerFields[fieldName];
    const sendFieldBecauseOwner = isOwner && isOwnerFields[fieldName];
    const sendFieldBecauseAuthorizedRepresentative = authorizedRepresentativeFields[fieldName];

    const shouldSendField =
      sendFieldBecauseController ||
      sendFieldBecauseOwner ||
      sendFieldBecauseAuthorizedRepresentative;
    // We return undefined so we can short-circuit the return and avoid writing a ternary for every field
    return shouldSendField ? true : undefined;
  };

  return {
    address:
      shouldSendField("address") &&
      formState.address?.addressLine1 &&
      formState.address.city &&
      formState.address.postalCode &&
      formState.address.stateOrProvince
        ? {
            addressLine1: formState.address.addressLine1,
            addressLine2: formState.address.addressLine2,
            city: formState.address.city,
            country: formState.address.country ?? "US",
            postalCode: formState.address.postalCode,
            stateOrProvince: formState.address.stateOrProvince
          }
        : undefined,
    birthDate:
      shouldSendField("birthDate") &&
      formState.birthDate?.day != null &&
      formState.birthDate?.month != null &&
      formState.birthDate?.year != null
        ? {
            day: formState.birthDate.day,
            month: formState.birthDate.month,
            year: formState.birthDate.year
          }
        : undefined,
    email: shouldSendField("email") && formState.email,
    governmentID: shouldSendField("governmentID") && formState.governmentID,
    name: shouldSendField("firstName") &&
      shouldSendField("lastName") && {
        firstName: formState.firstName,
        lastName: formState.lastName
      },
    phone: shouldSendField("phone") && { number: formState.phone },
    representativeID: shouldSendField("representativeID") && formState.representativeID,
    responsibilities: {
      isController: formState.isController,
      // isOwner should be undefined when isController is true, or the API pukes
      isOwner: shouldSendField("isOwner") && formState.isOwner,
      jobTitle: shouldSendField("jobTitle") ? formState.jobTitle : "",
      ownershipPercentage: shouldSendField("ownershipPercentage")
        ? formState.ownershipPercentage
        : 0
    }
  };
};

const findRepresentative = (
  representatives: Representative[],
  representativeID: string | undefined
): Representative | undefined =>
  representativeID
    ? representatives.find((rep) => rep.representativeID === representativeID)
    : undefined;

export const AddOwner = () => {
  const { facilitatorID } = useContext(FacilitatorContext);
  const { createRepresentative, patchRepresentative, representatives } =
    useContext(OnboardingContext);
  const { getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const { representativeID } = useParams();
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>(
    mapRepresentativeToFormState(findRepresentative(representatives, representativeID))
  );
  const representative = findRepresentative(representatives, representativeID);
  const { fields } = useValidatedFields(fieldsToValidate, ERROR_MESSAGE);
  const [isAuthorized, setIsAuthorized] = useState(
    !formState.isController && !formState.isOwner && !!formState.representativeID
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<OnboardingErrors<Representative>>({});
  const [ownershipPercentageError, setOwnershipPercentageError] = useState<string>();
  const [showWarning, setShowWarning] = useState(false);
  const [requiredFields, setRequiredFields] = useState<ValidatedFields[]>([
    "firstName",
    "lastName"
  ]);
  const [isRemovingRepresentative, setIsRemovingRepresentative] = useState(false);

  // Specific field errors and warnings.
  const [phoneError, setPhoneError] = useState(
    handleError(errors.phone?.countryCode || errors.phone?.number)
  );

  useEffect(() => {
    // if we're editing an already-existing representative, update the fields to be checked
    if (formState.representativeID) {
      if (formState.isOwner || formState.isController) {
        setRequiredFields((prev) => [...prev, /* "address", */ "email", "phone"]);

        if (!formState.birthDateProvided) {
          setRequiredFields((prev) => [...prev, "birthDate"]);
        }

        if (!formState.governmentIDProvided) {
          // Yuck! Is there a better way to handle this?
          if (document.getElementsByName("itin")[0]) {
            setRequiredFields((prev) => [...prev, "itin"]);
          } else if (document.getElementsByName("ssn")[0]) {
            setRequiredFields((prev) => [...prev, "ssn"]);
          }
        }

        if (formState.isOwner) {
          setRequiredFields((prev) => [...prev, "ownershipPercentage"]);
        }

        if (formState.isController) {
          setRequiredFields((prev) => [...prev, "jobTitle"]);
        }
      }
    }
  }, [
    formState.birthDateProvided,
    formState.governmentIDProvided,
    formState.isController,
    formState.isOwner,
    formState.representativeID
  ]);

  useEffect(() => {
    // if we're editing an already-existing representative, validate all fields on load
    if (formState.representativeID) {
      fields.validate(requiredFields);
    }
  }, [formState.representativeID, requiredFields]);

  const validateInput = (event: FocusEvent<HTMLInputElement>) => {
    const fieldName = fields.assertFieldName(event.currentTarget.name);
    fields.validate(fieldName, event.currentTarget);
    if (event.currentTarget.name === "ownershipPercentage") {
      if (formState.ownershipPercentage > 0 && formState.ownershipPercentage < 25) {
        setOwnershipPercentageError("Ownership percentage must be equal to or greater than 25%.");
      } else {
        setOwnershipPercentageError("");
        setErrors((prev) => ({ ...prev, responsibilities: { ownershipPercentage: undefined } }));
      }
    }
  };

  const onContinue = async () => {
    setIsLoading(true);

    if (!formState.isController && !formState.isOwner && !isAuthorized) {
      setShowWarning(true);
      setIsLoading(false);
      return;
    }

    const { isInvalid } = fields.validate(requiredFields);

    if (isInvalid()) {
      setIsLoading(false);
      return;
    }

    let result;
    if (formState.representativeID) {
      result = await patchRepresentative(mapFormStateToRepresentative(formState));
    } else {
      result = await createRepresentative(mapFormStateToRepresentative(formState));
    }

    const { data, response, error } = result;

    if (!data || error || !response.ok) {
      toast("There was an error saving the data. Please try again.");
      if (error) {
        setErrors(parseErrors<Representative>(error));
      }
      setIsLoading(false);
      return;
    }

    navigate("..");
  };

  /**
   * @TODO The reason why we need an `onXXXX` function for basically every field is because we can't pass the
   * event object to the `handleInput` function, which would allow us to do all the validation and state updating
   * in one place. This is because the legacy inputs, again, are not compatible with `handleInput` and require
   * their own validation and state updating functions, given their complex types and hard-coded assumptions
   *
   * Again let's fix this later
   *
   */
  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = fields.assertFieldName(event.currentTarget.name);
    const fieldValue = event.currentTarget.value;

    // Validate input on each keystroke if the field is already invalid
    if (fields.isInvalid(fieldName)) {
      fields.validate(fieldName, event.currentTarget);
    }

    setFormState((prev) => ({
      ...prev,
      [fieldName]: fieldValue
    }));
  };

  const onPercentageChange = (value: number) => {
    if (value >= 25) {
      setOwnershipPercentageError("");
    }
    setFormState((prev) => ({
      ...prev,
      ownershipPercentage: value
    }));
  };

  const onControllerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isController: e.target.checked
    }));
    setShowWarning(false);
  };

  const onOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isOwner: e.target.checked
    }));
    setShowWarning(false);
  };

  const onAuthorizedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAuthorized(e.target.checked);
    setShowWarning(false);
  };

  const handleGovernmentIDChange = useCallback((id: GovernmentID) => {
    setFormState((prev) => ({ ...prev, governmentID: id }));
  }, []);

  const handleBirthDateChange = (date: BirthDate | IncompleteBirthDate | undefined) => {
    if (date && isBirthDate(date)) {
      setFormState((prev) => ({ ...prev, birthDate: date }));
    }
  };

  const handlePhoneChange = (phone: string) => {
    // 0 is used by the input to indicate that the phone number is not set
    setFormState((prev) => ({ ...prev, phone }));

    if (!phone || phone === "") {
      setPhoneError("Phone is required.");
    } else {
      setPhoneError("");
    }
  };

  const hasRepresentatives = representatives.length > 0;
  const isFirstRep =
    !hasRepresentatives ||
    representatives.findIndex((r) => r.representativeID === representativeID) === 0;

  return (
    <div className={styles.content}>
      <div className={styles.layout}>
        <OwnersStepHeading
          subHeader={
            isFirstRep
              ? "Tell us about yourself and confirm you're authorized to enter into an agreement on behalf of your company."
              : undefined
          }
        />
        <FormGroup noGap={false} noMargins>
          <div className={styles.twoColumns}>
            <FloatingLabelInput
              error={handleError(errors.name?.firstName)}
              label="First name"
              minLength={1}
              name="firstName"
              onBlur={validateInput}
              onChange={onFieldChange}
              pattern="[A-Za-z]+"
              required
              value={formState.firstName}
              warning={fields.getMessageIfInvalid("firstName")}
            />
            <FloatingLabelInput
              error={handleError(errors.name?.lastName)}
              label="Last name"
              minLength={1}
              name="lastName"
              onBlur={validateInput}
              onChange={onFieldChange}
              pattern="[A-Za-z]+"
              required
              value={formState.lastName}
              warning={fields.getMessageIfInvalid("lastName")}
            />
          </div>
          <Checkbox
            checked={formState.isOwner}
            label={`${isFirstRep ? "I own" : "They are an owner with"} at least 25% stake in your company.`}
            name="isOwner"
            onChange={onOwnerChange}
          />
          <Checkbox
            checked={formState.isController}
            label={`${isFirstRep ? "I am" : "They are"} a control officer with significant management authority.`}
            name="isController"
            onChange={onControllerChange}
          />
          {isFirstRep && (
            <Checkbox
              checked={isAuthorized}
              label="I am an authorized representative/signatory."
              name="isAuthorized"
              onChange={onAuthorizedChange}
            />
          )}
          {showWarning && (
            <Alert
              type="warning"
              label="One of the statements above must be checked off in order to continue."
            />
          )}
          {formState.isOwner && (
            <FloatingLabelWrapper
              label="Percent ownership"
              as={PercentInput}
              className={styles.input}
              error={
                handleError(errors.responsibilities?.ownershipPercentage) ??
                ownershipPercentageError
              }
              name="ownershipPercentage"
              onValueChange={onPercentageChange}
              onBlur={validateInput}
              value={formState.ownershipPercentage}
              required
            />
          )}
          {formState.isController && (
            <FloatingLabelInput
              error={handleError(errors.responsibilities?.jobTitle)}
              label="Job title"
              minLength={1}
              name="jobTitle"
              onBlur={validateInput}
              onChange={onFieldChange}
              value={formState.jobTitle}
              warning={fields.getMessageIfInvalid("jobTitle")}
            />
          )}
          {(formState.isOwner || formState.isController) && (
            <>
              <GovernmentIDInput
                className={clsx(styles.noMargins, styles.input)}
                error={handleError(
                  errors.governmentID?.itin?.full ||
                    errors.governmentID?.itin?.lastFour ||
                    errors.governmentID?.ssn?.full ||
                    errors.governmentID?.ssn?.lastFour
                )}
                forceFloating={formState.governmentIDProvided}
                name="governmentID"
                onChange={handleGovernmentIDChange}
                placeholder={formState.governmentIDProvided ? "•••-••-••••" : undefined}
                value={formState.governmentID}
              />
              <BirthDateMaskingInput
                className={clsx(styles.noMargins)}
                error={handleError(
                  errors.birthDate?.day || errors.birthDate?.month || errors.birthDate?.year
                )}
                label="Date of birth"
                name="birthDate"
                onValueChange={handleBirthDateChange}
                value={formState.birthDate ?? undefined}
                // warn={ fields.getMessageIfInvalid("birthDateString")}
              />
              <FloatingLabelWrapper
                as={PhoneNumberInput}
                name="phone"
                label={"Phone Number"}
                className={styles.input}
                error={phoneError}
                onValueChange={handlePhoneChange}
                required
                value={formState.phone ?? ""}
              />
              <FloatingLabelInput
                autoComplete="email work"
                error={handleError(errors.email)}
                label="Email"
                name="email"
                onBlur={validateInput}
                onChange={onFieldChange}
                pattern={REGEX.EMAIL}
                type="email"
                value={formState.email ?? ""}
                warning={fields.getMessageIfInvalid("email")}
              />
              <FormGroup noGap={false}>
                <FloatingLabelWrapper
                  as={AddressAutoComplete}
                  error={handleError(errors.address?.addressLine1)}
                  facilitatorID={facilitatorID}
                  autoComplete="none"
                  label="Personal street address"
                  name="addressLine1"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState((prev) => ({
                      ...prev,
                      address: { ...prev.address, addressLine1: e.target.value }
                    }))
                  }
                  onSuggestionSelected={(suggestion: AddressSuggestion) =>
                    setFormState((prev) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        addressLine1: suggestion.addressLine1 ?? prev.address?.addressLine1,
                        addressLine2: suggestion.addressLine2 ?? "",
                        city: suggestion.city ?? prev.address?.city,
                        postalCode: suggestion.postalCode ?? prev.address?.postalCode,
                        stateOrProvince: suggestion.stateOrProvince ?? prev.address?.stateOrProvince
                      }
                    }))
                  }
                  required
                  value={formState.address?.addressLine1}
                />
                <FloatingLabelInput
                  label="Apartment, suite, or floor"
                  name="addressLine2"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      address: { ...prev.address, addressLine2: e.target.value }
                    }))
                  }
                  value={formState.address.addressLine2}
                  warning={handleError(errors.address?.addressLine2)}
                />
                <FloatingLabelInput
                  error={handleError(errors.address?.city)}
                  label="City"
                  name="city"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      address: { ...prev.address, city: e.target.value }
                    }))
                  }
                  required
                  value={formState.address.city}
                />
                <div className={styles.twoColumns}>
                  <Select
                    className={styles.stateSelect}
                    error={handleError(errors.address?.stateOrProvince)}
                    floatingLabelStyle
                    label="State"
                    name="stateOrProvince"
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        address: { ...prev.address, stateOrProvince: e.target.value }
                      }))
                    }
                    placeholder="--"
                    required
                    value={formState.address.stateOrProvince}
                  >
                    {stateOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    <optgroup label="US outlying territories">
                      {territoryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Armed Forces">
                      {armedForcesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                  <FloatingLabelInput
                    error={handleError(errors.address?.postalCode)}
                    inputMode="numeric"
                    label="Zip code"
                    maxLength={5}
                    minLength={5}
                    name="postalCode"
                    onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                      if (e.data.match(/[^0-9]/)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        address: { ...prev.address, postalCode: e.target.value }
                      }))
                    }
                    pattern="[0-9]{5}"
                    required
                    type="text"
                    value={formState.address.postalCode}
                  />
                </div>
              </FormGroup>
            </>
          )}
          {representative && (
            <Button
              buttonType="destructive"
              buttonStyle="text"
              onClick={() => setIsRemovingRepresentative(true)}
              fullWidth
            >
              Remove profile
            </Button>
          )}
          {isRemovingRepresentative && representative && (
            <RemoveOwnerConfirmation
              onRemove={() => {
                navigate("..");
              }}
              onCancel={() => {
                setIsRemovingRepresentative(false);
              }}
              representative={representative}
            />
          )}
        </FormGroup>
        <FooterButtons
          isLoading={isLoading}
          onBack={() => (hasRepresentatives ? navigate("..") : navigate(getPreviousStepUrl()))}
          onContinue={onContinue}
        />
      </div>
    </div>
  );
};
