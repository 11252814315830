let env: "development" | "staging" | "production";

const isLocalHostname = (hostname: string) =>
  hostname === "localhost" || hostname === "local.moov.io";
const isLocalViteEnv = () => import.meta.env.DEV;

const isStagingHostname = (hostname: string) => hostname.includes("moov-staging.");

const calculateEnvFromHostname = (shouldDisplayWarning = true) => {
  const hostname = window.location.hostname;
  const environment = isLocalHostname(hostname)
    ? "development"
    : isStagingHostname(hostname)
      ? "staging"
      : "production";
  if (environment !== "production" && shouldDisplayWarning) {
    //eslint-disable-next-line no-console
    console.warn(
      "🟠 No environment detected through Vite env variables, falling back to URL matching only"
    );
  }
  return environment;
};

/**
 * Canonical environment detection for all monorepo apps
 *
 * 🟠 It works best with apps built with Vite
 *
 * If the required variables to determine the environment are not available,
 * it will make a best effort guess based on the hostname, and will log a warning in the console
 *
 * @param {boolean} urlOnlyEnvResolution - If true, only the URL will be used to determine the environment. This is useful for apps where we use modResponse to test them
 * @returns {string} - The environment name
 */
export const getEnv = (urlOnlyEnvResolution = false): typeof env => {
  if (env) return env;
  const hostname = window.location.hostname;
  // import.meta.env.DEV is replaced at BUILD TIME (npm run build) by Vite with a boolean
  // indicating if the environment is development. Therefore it may be `undefined` if the app
  // is not built with Vite
  //
  // I was unsure if it might also throw an exception at runtime if the app was
  // not built with Vite, so being extra cautious and using a try/catch
  //
  // https://vitejs.dev/guide/env-and-mode.html#modes
  try {
    if (urlOnlyEnvResolution) {
      env = calculateEnvFromHostname(!urlOnlyEnvResolution);
      if (env !== "production") {
        // eslint-disable-next-line no-console
        console.log(
          "ℹ️ Using URL matching to determine environment. This mode IS compatible with modResponse"
        );
      }
      // if import.meta.env.DEV is defined, then resolve the environment based on the Vite env
      // otherwise fallback to only URL matching
    } else if (isLocalViteEnv() !== undefined) {
      env = isLocalViteEnv()
        ? "development"
        : isStagingHostname(hostname)
          ? "staging"
          : "production";
    } else {
      env = calculateEnvFromHostname();
    }
  } catch (_) {
    env = calculateEnvFromHostname();
  }
  return env;
};

export const isDevelopment = () => getEnv() === "development";
export const isStaging = () => getEnv() === "staging";
export const isProduction = () => getEnv() === "production";
