import { useContext, useState } from "react";
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import useAccountLocking from "hooks/useAccountLocking";
import { useAuthenticatedAdminRoute } from "hooks/useAuthenticatedAdminRoute";
import { StepContext } from "components/dialog/StepModal";
import { ToastEntry } from "components/toaster/Toaster";
import { Action, MoovAdminResource } from "api/Role.model";
import { Account } from "api/v2";
import { APIResponse, ErrorResponse } from "api/v2/request";
import { APIContext } from "contexts/APIContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";
import { pruneAccount, pruneAccountForPatch } from "helpers/accounts";
import {
  ERR_UNEXPECTED,
  getErrorMessage,
  handleAccountErrors as handleAccountErrorsImport,
  handleErrorResponse,
  handleUnderwritingErrors
} from "helpers/errorMessages";
import { retryRequest } from "helpers/retryRequest";
import { AccountSetupContext } from "./AccountSetupContext";
import { FileState, initialState } from "./AccountSetupReducer";

interface AccountCalls {
  // API call to create an account
  createAccount: () => void;
  // API call to update an account
  updateAccount: () => void;
  // API call to update underwriting
  submitTransactionDetails: () => void;
  // API call to upload statements
  uploadStatements: () => void;
  // Loading state
  loading: boolean;
}

export default function useAccountModalCalls(): AccountCalls {
  const { moov } = useContext(APIContext);
  const {
    account,
    savedAccountData,
    capabilities,
    underwriting,
    files,
    dispatch,
    shouldRefresh,
    setErrorMessages,
    errorMessages,
    hasUnsavedData,
    setCapabilityRequirements
  } = useContext(AccountSetupContext);
  const { next, goTo, steps, activeStepIndex, stepCount } = useContext(StepContext);
  const { mode, facilitatorID } = useContext(FacilitatorContext);
  const { userCan } = useContext(UserContext);
  const isAdminRoute = useAuthenticatedAdminRoute();

  const { underwritingLocked, verificationLocked } = useAccountLocking();
  const canWriteAdminAccount = userCan(Action.Write, MoovAdminResource.adminAccountPatch);

  const [loading, setIsLoading] = useState(false);

  const goToFirstError = (errors: ToastEntry[]) => {
    const businessDetailsErrors = [
      "legalBusinessName",
      "doingBusinessAs",
      "businessType",
      "ein",
      "foreignID",
      "industryCodes"
    ];
    const individualDetailsErrors = [
      "firstName",
      "middleName",
      "lastName",
      "suffix",
      "birthDate",
      "ssn",
      "itin"
    ];

    if (account.accountType === "business") {
      if (errors.some((error) => businessDetailsErrors.includes(error.key))) {
        return goTo("busDetails");
      }
    }
    if (account.accountType === "individual") {
      if (errors.some((error) => individualDetailsErrors.includes(error.key))) {
        return goTo("indvDetails");
      }
    }
  };

  const handleAccountErrors = (error: ErrorResponse) => {
    const formattedErrors: ToastEntry[] = handleAccountErrorsImport(error).map((err) => ({
      key: err.key,
      message: err.title ? `${err.title}: ${err.value}` : err.value,
      status: "error"
    }));

    if (formattedErrors.length === 0) {
      const errorMessage = handleErrorResponse(error);
      formattedErrors.push({
        key: "accountError",
        message: errorMessage,
        status: "error"
      });
    }
    goToFirstError(formattedErrors);
    setErrorMessages(formattedErrors);
  };

  const handleTransactionErrors = (error: ErrorResponse) => {
    const transactionErrors = handleUnderwritingErrors(error);
    const formattedErrors = transactionErrors.map(
      (err): ToastEntry => ({
        key: err.key,
        message: err.title ? `${err.title}: ${err.value}` : err.value,
        status: "error"
      })
    );
    setErrorMessages(formattedErrors);
  };

  const handleNext = () => {
    hasUnsavedData.current = false;

    const shouldRefreshCapabilities =
      steps.some((step) => step.id === "busSuccess" || step.id === "indvSuccess") &&
      activeStepIndex === stepCount - 1;

    shouldRefreshCapabilities ? getCapabilities() : next();
  };

  const createAccount = async () => {
    setIsLoading(true);

    const postData = {
      ...pruneAccount(account),
      capabilities
    };
    if (mode === "sandbox") {
      const [result] = await moov.accounts.getTOSToken();
      postData.termsOfService = result;
    }

    moov.accounts.create(facilitatorID, postData).then(async ([result, err]) => {
      if (err) {
        setIsLoading(false);
        handleAccountErrors(err);
        return;
      }

      if (result) savedAccountData.current = result;
      dispatch({
        type: "account",
        value: deepMerge(initialState.account, result)
      });
      shouldRefresh.current = true;
      handleNext();
      setIsLoading(false);
    });
  };

  const updateAccount = () => {
    if (verificationLocked && !hasUnsavedData.current) return handleNext();
    if (!savedAccountData.current || savedAccountData.current === null || !account.accountID) {
      setErrorMessages([
        ...errorMessages,
        {
          message: ERR_UNEXPECTED,
          key: "accountError",
          status: "error"
        }
      ]);
      return;
    }

    const patchData = pruneAccountForPatch(savedAccountData.current, account);

    if (Object.keys(patchData).length === 0) return handleNext();

    setIsLoading(true);

    const accountCall: APIResponse<Account> =
      canWriteAdminAccount && isAdminRoute
        ? moov.accounts.adminPatch(facilitatorID, savedAccountData.current.accountID, patchData)
        : moov.accounts.patch(facilitatorID, savedAccountData.current.accountID, patchData);

    accountCall.then(([result, err]) => {
      if (err) {
        setIsLoading(false);
        handleAccountErrors(err);
        return;
      }

      dispatch({
        type: "account",
        value: deepMerge(initialState.account, result)
      });

      if (result) savedAccountData.current = result;
      shouldRefresh.current = true;
      handleNext();
      setIsLoading(false);
    });
  };

  const submitTransactionDetails = () => {
    const shouldSaveAccount =
      account.profile?.business?.description !==
      savedAccountData.current?.profile.business?.description;

    if (Object.values(underwriting).every((field) => !field)) return handleNext();
    if (!account.accountID) {
      setErrorMessages([
        {
          message: ERR_UNEXPECTED,
          key: "accountError",
          status: "error"
        }
      ]);
      return;
    }

    if (underwritingLocked) {
      return shouldSaveAccount ? updateAccount() : handleNext();
    }

    setIsLoading(true);
    moov.underwriting.put(account.accountID, facilitatorID, underwriting).then(([_, err]) => {
      if (err) {
        setIsLoading(false);
        handleTransactionErrors(err);
        return;
      }

      shouldRefresh.current = true;
      setIsLoading(false);
      return shouldSaveAccount ? updateAccount() : handleNext();
    });
  };

  const uploadStatements = () => {
    if (!account.accountID) {
      setErrorMessages([
        {
          message: ERR_UNEXPECTED,
          key: "accountError",
          status: "error"
        }
      ]);
      return;
    }

    const filesPendingUpload = files.filter((file: FileState) => file.status === "pending");
    const existingFiles = files.filter((file: FileState) => file.status !== "pending");

    if (filesPendingUpload.length === 0) return handleNext();

    const uploadCalls = filesPendingUpload.map((upload: FileState) => {
      const fileFormData = new FormData();
      fileFormData.append("file", upload.file);
      fileFormData.append("filePurpose", "merchant_underwriting");
      return moov.accounts.files.upload(facilitatorID, account.accountID!, fileFormData);
    });

    Promise.allSettled(uploadCalls)
      .then(async (uploadResultsRaw) => {
        const uploadResults: { status: string; error?: string }[] = await Promise.all(
          uploadResultsRaw.map((r) => {
            if (r.status !== "fulfilled") {
              return getErrorMessage(r.reason, "There was an error uploading your document.").then(
                (message) => {
                  return { status: "rejected", error: message };
                }
              );
            }
            return { status: "fulfilled" };
          })
        );
        return uploadResults;
      })
      .then((uploadResults) => {
        const results: FileState[] = filesPendingUpload.map((originalFile, index) => {
          const rawResult = uploadResults[index];
          if (rawResult.status === "rejected") {
            return {
              ...originalFile,
              status: "error",
              errorMessage: rawResult.error
            };
          }
          return { ...filesPendingUpload[index], status: "success" };
        });
        const erroredResults = results.filter((result) => result.status === "error");

        dispatch({
          type: "uploadFiles",
          value: [...existingFiles, ...results]
        });
        if (!erroredResults.length) {
          handleNext();
          setIsLoading(false);
        }
      });
  };

  const getCapabilities = () => {
    if (savedAccountData.current?.accountID && facilitatorID) {
      retryRequest(() => {
        if (!savedAccountData.current?.accountID) return Promise.reject();
        return moov.capabilities
          .list(facilitatorID, savedAccountData.current?.accountID)
          .then(([results]) => {
            if (results && results.length) {
              setCapabilityRequirements(results);
            }
            next();
            setIsLoading(false);
          });
      });
    } else {
      next();
      setIsLoading(false);
    }
  };

  return {
    createAccount,
    updateAccount,
    submitTransactionDetails,
    uploadStatements,
    loading
  };
}
