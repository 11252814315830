import { camelCaseWordToSentence } from "@moovfinancial/common/utils/stringManipulation";
import { Responsibilities } from "api/Account.model";
import {
  Account,
  AccountUnderwriting,
  Address,
  Business,
  CardVolumeDistribution,
  Fulfillment,
  GovernmentID,
  Individual,
  Name,
  Representative,
  VolumeByCustomerType
} from "api/v2";
import {
  ErrorResponse,
  HTTPError,
  RecursiveErrorObject,
  cleanErrorMessage,
  errorObjectToString,
  getErrorsAsObject,
  getErrorsAsString,
  isHTTPError,
  isHTTPErrorWrappedObject,
  isLegacyHTTPErrorRawObject,
  isNetworkError,
  isPostProcessingError
} from "api/v2/request";

export const ERR_UNEXPECTED =
  "Something unexpected happened. Wait a minute and try again, or submit a ticket via support.moov.io if the problem persists.";
const PASSWORD_LENGTH_ERROR = "Passwords must be a minimum of 12 characters";
const PASSWORD_UPPERCASE_ERROR = "Password must contain at least one uppercase character";
const PASSWORD_LOWERCASE_ERROR = "Password must contain at least one lowercase character";
const PASSWORD_SYMBOL_ERROR = "Password must contain at least one special character";
const PASSWORD_NUMBER_ERROR = "Password must contain at least one number";
interface ErrorMessage {
  key: string;
  value: string;
  title?: string;
}

/**
 *
 * Function similar to request.ts' getErrorsAsArray but specialized to apply a mapping between
 * the LAST level of the property's path (e.g. from customerSupport.address.city, we would look only at "city")
 * to map the property name to a "title"
 *
 */
export const extractHTTPErrorsAsErrorMessages = (
  responseOrErrors: HTTPError | RecursiveErrorObject,
  keyMap?: Record<string, string>
): ErrorMessage[] => {
  const errors = isHTTPError(responseOrErrors)
    ? getErrorsAsObject(responseOrErrors)
    : responseOrErrors;
  const res: ErrorMessage[] = [];
  Object.entries(errors).forEach(([key, value]) => {
    if (typeof value === "string") {
      const title = (!!keyMap && keyMap[key]) || camelCaseWordToSentence(key) || "Unknown";
      res.push({
        // this is to cater to legacy code that expectes the default error key to be capitalized
        key: key === "error" ? "Error" : key,
        title,
        value: cleanErrorMessage(value)
      });
    } else if (!!value && typeof value === "object") {
      res.push(...extractHTTPErrorsAsErrorMessages(value, keyMap));
    }
  });
  return res;
};

export function is4xx(status: number): boolean {
  return status >= 400 && status < 500;
}

/**
 * Very generic and un-specialized fallback function to try to extract useful error messages from an ErrorResponse
 *
 * @param err - The error to returned by our API client
 * @returns Error message to display to the user
 */
export function handleErrorResponse(response: ErrorResponse): string {
  if (isNetworkError(response))
    return "Something went wrong with the connection. Check your network and try again.";
  if (isPostProcessingError(response)) return "Post-processing error, please try again.";
  if (isHTTPError(response)) {
    // First we try to extract any kind of embedded error message in the response
    const error = getErrorsAsString(response);
    // Only when we fail we fallback to the default message by HTTP status code
    if (error === "") {
      return getDefaultHTTPErrorMessage(response);
    }
    return error;
  }
  return ERR_UNEXPECTED;
}

function getDefaultHTTPErrorMessage(err: HTTPError): string {
  let msg: string;

  // 5XX errors
  if (is5xx(err.resp?.status)) {
    return HTTP_5XX_ERROR;
  }

  // 4XX and other
  switch (err.resp?.status) {
    case 401:
      msg = "You are no longer authenticated. Please sign in again.";
      break;
    case 402:
      msg = "There was an error processing the request. Please try again.";
      break;
    case 403:
      msg = "You are not authorized to perform this action.";
      break;
    case 404:
      msg = "The requested resource was not found.";
      break;
    case 408:
      msg = "The request timed out. Please try again, or contact us if the problem persists.";
      break;
    case 422:
      // We shouldn't get here. The FE code should make sure request bodies are valid.
      msg = "The request was missing required fields or had invalid values.";
      break;
    default:
      // It's our fault here because we should've anticipated and prevented it from happening
      msg = ERR_UNEXPECTED;
      break;
  }

  return msg;
}

export type AccountErrorKeys =
  | keyof Pick<Account, "accountType" | "foreignID">
  | keyof Omit<
      Business,
      "taxIDProvided" | "representatives" | "ownersProvided" | "taxID" | "address"
    >
  | keyof Omit<
      Individual,
      "governmentIDProvided" | "birthDateProvided" | "name" | "governmentID" | "address"
    >
  | keyof Name
  | keyof GovernmentID
  | keyof Address
  | "ein";

const accountKeyMap: Record<AccountErrorKeys, string> = {
  accountType: "Account type",
  email: "Email",
  phone: "Phone",
  website: "Website",
  description: "Description",
  industryCodes: "Industry codes",
  foreignID: "Foreign ID",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  stateOrProvince: "State or province",
  postalCode: "Postal code",
  country: "Country",
  firstName: "First name",
  middleName: "Middle name",
  lastName: "Last name",
  suffix: "Suffix",
  legalBusinessName: "Business name",
  doingBusinessAs: "Doing business as",
  businessType: "Business type",
  birthDate: "Birth date",
  ssn: "SSN",
  itin: "ITIN",
  ein: "EIN",
  primaryRegulator: "Primary regulator"
};

export const handleAccountErrors = (error: ErrorResponse): ErrorMessage[] => {
  let formattedErrors: ErrorMessage[] = [];
  if (isHTTPError(error)) {
    formattedErrors = extractHTTPErrorsAsErrorMessages(error, accountKeyMap);
  } else {
    const otherErrors = handleErrorResponse(error);
    formattedErrors.push({ key: "Error", value: otherErrors });
  }
  return formattedErrors;
};

type RepresentativeErrorKeys =
  | keyof Omit<
      Representative,
      "createdOn" | "updatedOn" | "disabledOn" | "responsibilities" | "representativeID" | "name"
    >
  | keyof Omit<Responsibilities, "isOwner" | "isController">
  | keyof Omit<Name, "middleName" | "suffix">;

const repKeyMap: Record<RepresentativeErrorKeys, string> = {
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  phone: "Phone",
  address: "Address",
  birthDate: "Birth date",
  birthDateProvided: "Birth date",
  governmentID: "Government ID",
  governmentIDProvided: "Government ID",
  jobTitle: "Job title",
  ownershipPercentage: "Ownership percentage"
};

export const handleRepresentativeErrors = (response: ErrorResponse): ErrorMessage[] => {
  if (isHTTPError(response)) {
    return extractHTTPErrorsAsErrorMessages(response, repKeyMap);
  }
  return [];
};

type UnderwritingErrorKeys =
  | keyof Omit<
      AccountUnderwriting,
      "cardVolumeDistribution" | "fulfillment" | "volumeByCustomerType"
    >
  | keyof CardVolumeDistribution
  | keyof VolumeByCustomerType
  | keyof Fulfillment;

const underwritingKeyMap: Record<UnderwritingErrorKeys, string> = {
  averageMonthlyTransactionVolume: "Average monthly transaction volume",
  averageTransactionSize: "Average transaction size",
  businessToBusinessPercentage: "Business to business percentage",
  cardPresentPercentage: "Card present percentage",
  consumerToBusinessPercentage: "Consumer to business percentage",
  debtRepaymentPercentage: "Debt repayment percentage",
  ecommercePercentage: "E-commerce percentage",
  hasPhysicalGoods: "Has physical goods",
  isShippingProduct: "Is shipping product",
  mailOrPhonePercentage: "Mail or phone percentage",
  maxTransactionSize: "Maximum transaction size",
  returnPolicy: "Return policy",
  shipmentDurationDays: "Shipment duration days",
  status: "Status"
};

export const handleUnderwritingErrors = (response: ErrorResponse): ErrorMessage[] => {
  let formattedErrors: ErrorMessage[] = [];
  if (isHTTPError(response)) {
    formattedErrors = extractHTTPErrorsAsErrorMessages(response, underwritingKeyMap);
  } else {
    const non422Errors = handleErrorResponse(response);
    formattedErrors.push({ key: "Error", value: non422Errors });
  }

  return formattedErrors;
};

// @TODO: Move these specific error handling closer to where the components or fetchers are
export const handleFileDownloadErrors = (response: ErrorResponse): string => {
  if (isLegacyHTTPErrorRawObject(response) && "FileName" in response.body) {
    return `Filename: ${response.body.FileName}`;
  }
  // support the new error-wrapper error responses
  if (isHTTPErrorWrappedObject(response) && "Filename" in response.body.error) {
    return `Filename: ${response.body.error.Filename}`;
  }
  return handleErrorResponse(response);
};

export const HTTP_5XX_ERROR = "Something went wrong on our end.";

export function is5xx(status: number): boolean {
  return status >= 500 && status < 600;
}

export const CONNECTION_ERROR =
  "Something went wrong with the connection. Check your network and try again.";

/**
 *
 * Used mainly in legazy. Yes, legacy + lazy because whoever wrote the legacy code was too lazy to
 * realize it was not a great idea to mix Errors, ResponseErrors, etc. in error handling.
 *
 * This function tries to handle everything in a uniform way: Responses, Errors (incl NetworkError) and ErrorResponses
 *
 * @deprecated: Use handleErrorResponse or getErrorMessage instead
 *
 */
export function universalErrorHandlerToString(err: any): string {
  if (typeof err === "object") {
    if (isHTTPError(err) || isNetworkError(err) || isPostProcessingError(err)) {
      return handleErrorResponse(err);
    }
    // Test for NetworkError (NOTE: NOT THE SAME as NetworkErrorResponse 🙄 you gotta love legacy code)
    if (err.name && err.name === "NetworkError") {
      return `${CONNECTION_ERROR}. ${err.message ?? ""}`;
    }
    if (err.status) {
      // It's a Response (note: NOT an ErrorResponse 🙄)
      // we make a synthetic ErrorResponse to use the existing error handling
      const errorResponse: HTTPError = {
        resp: err,
        body: {},
        type: "http"
      };
      return getDefaultHTTPErrorMessage(errorResponse);
    }
  }
  if (typeof err === "string") {
    return cleanErrorMessage(err) || ERR_UNEXPECTED;
  }
  if (err instanceof Error) {
    return cleanErrorMessage(err.message) || ERR_UNEXPECTED;
  }
  return ERR_UNEXPECTED;
}
interface PasswordError {
  password: string;
}
export async function handlePasswordErrors(err: any): Promise<string> {
  let finalMessage = universalErrorHandlerToString(err);

  await err.json().then((stringErr: PasswordError) => {
    //length
    if (stringErr.password.includes("the length must be between 12 and 128")) {
      finalMessage = PASSWORD_LENGTH_ERROR;
    }
    //uppercase
    if (stringErr.password.includes("required to have a uppercase letter")) {
      finalMessage = PASSWORD_UPPERCASE_ERROR;
    }
    //lowercase
    if (stringErr.password.includes("required to have a lowercase letter")) {
      finalMessage = PASSWORD_LOWERCASE_ERROR;
    }
    //symbol
    if (stringErr.password.includes("required to have a symbol")) {
      finalMessage = PASSWORD_SYMBOL_ERROR;
    }
    //number
    if (stringErr.password.includes("required to have a digit")) {
      finalMessage = PASSWORD_NUMBER_ERROR;
    }
  });
  return finalMessage;
}

/**
 *
 * General function to extract a single string from an ErrorResponse.
 *
 * It tries to extract the error message from the ErrorResponse, and if it's not able to do so,
 * it falls back to the fallBackMessage.
 *
 */
export async function getErrorMessage(
  response: ErrorResponse | { json: () => Promise<HTTPError["body"]> } | undefined,
  fallBackMessage: string = ERR_UNEXPECTED
): Promise<string> {
  if (!response) return fallBackMessage;
  if (isNetworkError(response) || isPostProcessingError(response))
    return handleErrorResponse(response);
  // typesafe portion of error handling
  if (isHTTPError(response)) {
    const errorString = getErrorsAsString(response);
    return errorString !== "" ? errorString : fallBackMessage;
  } else {
    // if it's not an HTTPError, we try to async resolve the error body and treat it as a RecursiveErrorObject
    try {
      const errorObject = await response.json();
      // this is a last resort try to interpret the result of response.json() as a valid error
      // the casting is not safe, thus wrapping it in the try/catch
      return errorObjectToString(errorObject as any) || fallBackMessage;
    } catch (_error) {
      return fallBackMessage;
    }
  }
}
