import { Fragment, useEffect, useState } from "react";
import type { components } from "@moovfinancial/common/types/__generated-types__/api";
import { Fee } from "./Fee";
import styles from "./Pricing.module.scss";

type FeePlan = components["schemas"]["FeePlan"];
type FeeItem = components["schemas"]["Fee"];
type FeeCategory = components["schemas"]["Fee"]["feeCategory"];

type GroupedFeePlan = FeePlan & {
  planID: string;
  groupedFees: Record<string, FeeItem[]>;
};

type PricingProps = {
  fetcher?: () => Promise<FeePlan[]>;
};

const feeCategoryDisplayName = (category: FeeCategory) => {
  switch (category) {
    case "ach":
      return "ACH";
    case "card-acquiring":
      return "Card acquiring";
    case "network-passthrough":
      return "Network fees";
    case "card-other":
      return "Transaction management fees";
    case "card-pull":
    case "card-push":
    case "rtp":
      return "Instant payments";
    default:
      return "Platform fees";
  }
};

const categoryOrder = [
  "ACH",
  "Card acquiring",
  "Instant payments",
  "Transaction management fees",
  "Platform fees",
  "Network fees"
];

/**
 *  Very specialized component that displays Billing Plans pricing information returned by the fetcher function.
 */
export function Pricing({ fetcher }: PricingProps) {
  const [feePlans, setFeePlans] = useState<GroupedFeePlan[]>();

  const init = async () => {
    const fetchFn = fetcher || (() => []);
    const plans = await fetchFn();
    const groupedPlans = plans?.map((plan) => ({
      ...plan,
      groupedFees: plan.fees?.reduce(
        (acc, fee) => {
          const category = feeCategoryDisplayName(fee.feeCategory);
          acc[category] = [...(acc[category] || []), fee];
          return acc;
        },
        {} as Record<string, FeeItem[]>
      )
    })) as GroupedFeePlan[];

    // Sort fees within each group
    groupedPlans.forEach((plan) => {
      Object.keys(plan.groupedFees).forEach((category) => {
        // Sorts fees by those with "volume" first, then alphabetically
        plan.groupedFees[category].sort((a, b) => {
          const aHasVolume = a.billableMetricCode?.toLowerCase().includes("volume") || false;
          const bHasVolume = b.billableMetricCode?.toLowerCase().includes("volume") || false;

          if (aHasVolume && !bHasVolume) return -1;
          if (!aHasVolume && bHasVolume) return 1;

          return (a.displayName || "").localeCompare(b.displayName || "");
        });
      });
    });

    setFeePlans(groupedPlans);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <>
      {feePlans?.map((plan) => (
        <Fragment key={plan.planID}>
          {categoryOrder.map((category) => {
            const fees = plan.groupedFees[category];
            if (!fees || fees.length === 0) return null;
            return (
              <Fragment key={category}>
                <h3 className={styles.feeCategory}>{category}</h3>
                <div className={styles.section}>
                  {fees.map((item) => (
                    <Fee key={item.feeID} fee={item} />
                  ))}
                </div>
              </Fragment>
            );
          })}
        </Fragment>
      ))}
    </>
  );
}
