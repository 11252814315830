import { components } from "../../types/__generated-types__/api";

export type BirthDate = components["schemas"]["BirthDate"];
export interface IncompleteBirthDate {
  day: string;
  month: string;
  year: string;
}

export const EMPTY_STATE = "MM/DD/YYYY";
const ALT_EMPTY_STATE = "00/00/0000";
const MAX_DAY = 31;
const MAX_MONTH = 12;

/**
 * Accepts a BirthDate or IncompleteBirthDate and returns a boolean indicating if the value is a BirthDate
 *
 * @param value The BirthDate to check
 * @returns A type narrowed BirthDate
 */
export function isBirthDate(value: BirthDate | IncompleteBirthDate): value is BirthDate {
  return (
    (typeof value.day === "number" ||
      (!value.day.includes("D") && !Number.isNaN(parseInt(value.day, 10)))) &&
    (typeof value.month === "number" ||
      (!value.month.includes("M") && !Number.isNaN(parseInt(value.month, 10)))) &&
    (typeof value.year === "number" ||
      (!value.year.includes("Y") && !Number.isNaN(parseInt(value.year, 10))))
  );
}

/**
 * Accepts a partial date string and returns a string formatted as a date with 0 padding (##/##/####)
 *
 * @param value The string to format
 * @returns The value formatted as a date (##/##/####)
 */
export function stringToFormattedDateString(value: string) {
  if (!value || value === EMPTY_STATE || value === ALT_EMPTY_STATE) {
    return EMPTY_STATE;
  }

  let [month, day, year] = value.split("/");

  month = (month ?? "").padStart(2, "0");
  day = (day ?? "").padStart(2, "0");
  year = (year ?? "").padEnd(4, "0");

  return `${month}/${day}/${year}`;
}

/**
 * Accepts a partial date string and returns a string formatted as a date with placeholder characters (##/##/####)
 *
 * @param value The string to format
 * @returns The value formatted as a date (##/##/####)
 */
export function formatDateInput(value: string) {
  if (!value || value === EMPTY_STATE) {
    return "";
  }

  const clean = value.replace(/[/MDY]/g, "").slice(0, 8);
  const cleanAndPadded = `${clean}${clean.length < 8 ? "MMDDYYYY".slice(-(8 - clean.length)) : ""}`;

  return `${cleanAndPadded.slice(0, 2)}/${cleanAndPadded.slice(2, 4)}/${cleanAndPadded.slice(4)}`;
}

/**
 * Accepts a formatted Date string (##/##/####) and returns a Date or undefined if the value is incomplete
 *
 * @param value The formatted date string to convert to a Date
 * @returns A Date object
 */
export function formattedDateStringToDate(value: string) {
  const [m, d, year] = value
    .replace(/DMY/g, "")
    .split("/")
    .map((segment) => parseInt(segment, 10));

  let month = m < 1 ? 1 : m;
  month = month > MAX_MONTH ? MAX_MONTH : month;
  let day = d < 1 ? 1 : d;
  day = day > MAX_DAY ? MAX_DAY : day;

  // If we have less than 4 year digits, any of the values are 0, or any of the values are NaN the
  // user is probably still entering values, return undefined
  if (year < 1000 || [month, day, year].some((segment) => segment === 0 || Number.isNaN(segment))) {
    return undefined;
  }

  const now = new Date();
  now.setUTCHours(0, 0, 0, 0);
  now.setUTCFullYear(year, month - 1, day);
  return now;
}

/**
 * Accepts a formatted Date string (##/##/####) and returns a BirthDate
 *
 * @param value The formatted date string to convert to a BirthDate
 * @returns A BirthDate object
 */
export function formattedDateStringToBirthDate(value: string): IncompleteBirthDate {
  const [month, day, year] = value.split("/");

  return { day: day ?? "", month: month ?? "", year: year ?? "" };
}

/**
 * Accepts a BirthDate or IncompleteBirthDate and returns a formatted date string (##/##/####)
 *
 * @param value The BirthDate or IncompleteBirthDate to be converted to a formatted string
 * @returns A formatted date string (##/##/####)
 */
export function birthDateToFormattedString(value: BirthDate | IncompleteBirthDate) {
  const day = value.day.toString().padStart(2, "0");
  const month = value.month.toString().padStart(2, "0");
  const year = value.year.toString().padEnd(4, "0");
  return `${month}/${day}/${year}`;
}

/**
 * Accepts a Date and returns a formatted date string (##/##/####)
 *
 * @param value The date to be converted to a formatted string
 * @returns The formatted date string (##/##/####)
 */
export function dateToFormattedString(value: Date) {
  const month = (value.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = value.getUTCDate().toString().padStart(2, "0");
  const year = value.getUTCFullYear().toString().padEnd(4, "0");

  return `${month}/${day}/${year}`;
}

/**
 * Converts a Date to a BirthDate
 *
 * @param value The date to be converted to a BirthDate
 * @returns A BirthDate object
 */
export function dateToBirthDate(value: Date): BirthDate {
  return {
    day: value.getUTCDate(),
    month: value.getUTCMonth() + 1,
    year: value.getUTCFullYear()
  };
}

/**
 * Converts a BirthDate to a Date
 *
 * @param value The BirthDate to be converted to a Date
 * @returns A Date object
 */
export function birthDateToDate(value: BirthDate): Date {
  return new Date(Date.UTC(value.year, value.month - 1, value.day));
}
