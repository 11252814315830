import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "@moovfinancial/common/types/__generated-types__/api";

export type { Middleware };

const DEFAULT_API_BASE_PATH = "/api";

/**
 * Creates an OpenAPI client with the option of customizing the baseUrl and middlewares
 */
export const create = (
  { baseUrl, middlewares }: { baseUrl: string; middlewares: Middleware[] } = {
    baseUrl: DEFAULT_API_BASE_PATH,
    middlewares: []
  }
) => {
  const openApi = createClient<paths>({ baseUrl });

  const removeUndefinedMiddleware: Middleware = {
    onRequest(req, _options) {
      const queryParams = req.params.query;
      if (queryParams) {
        Object.keys(queryParams).forEach((key) =>
          queryParams[key] === undefined ? delete queryParams[key] : {}
        );
      }
      return req;
    }
  };

  openApi.use(removeUndefinedMiddleware);

  middlewares.forEach((middleware) => openApi.use(middleware));

  return openApi;
};

export default { create };
