import {
  capitalize,
  dotSeparatedStringToSentence,
  sentenceToCamelCaseWord
} from "@moovfinancial/common/utils/stringManipulation";

/**
 * The request wasn't able to reach the server. This can happen when the app is
 * offline or there are other downstream connection issues.
 */
export type NetworkError = {
  type: "network";
  error: TypeError | DOMException;
};

/**
 * According to some of our existing code before this commit, some errors might have nested
 * error objects in them, so accounting for that here.
 */
export type RecursiveErrorObject = { [key: string]: string | RecursiveErrorObject };

/**
 * The request reached the server, but the server returned a status code outside
 * of the range [200, 400).
 */
export type HTTPError = {
  type: "http";
  resp: Response;
  // old 422 errors where returned directly in the body as a dict and 409 errors as a string
  body?:
    | RecursiveErrorObject
    | string
    | { error: RecursiveErrorObject }
    | { error: string[] }
    | string[];
};

export const isHTTPError = (response?: any): response is HTTPError => {
  return !!response && !!response.type && response.type === "http" && response.resp?.status;
};

/**
 *  In some endpoints, we might be getting errors directly as strings in the body.
 *
 *  To be clear: I did not confirm that's the case, this is more defensive programming than anything else
 *
 */
export type LegacyHTTPErrorRawString = HTTPError & {
  body: string;
};

const isLegacyHTTPErrorRawString = (
  response: ErrorResponse
): response is LegacyHTTPErrorRawString =>
  isHTTPError(response) && !!response.body && typeof response.body === "string";

/**
 *  In old / non-compliant-with-new-patterns endpoints, we are getting errors as an object directly in the body
 */
export type LegacyHTTPErrorRawObject = HTTPError & {
  body: RecursiveErrorObject;
};

export const isLegacyHTTPErrorRawObject = (
  error: ErrorResponse
): error is LegacyHTTPErrorRawObject =>
  isHTTPError(error) &&
  !!error.body &&
  typeof error.body === "object" &&
  !Array.isArray(error.body) &&
  !Array.isArray(error.body?.error);

/**
 * According to our FE code, we have some errors that are returned
 * as strings wrapped in an `error` object
 *
 * e.g. https://github.com/moovfinancial/frontend/blob/main/dashboard/src/pages/documents/DocumentPreviewModal/DocumentDecision.tsx#L105
 */
export type HTTPErrorWrappedString = HTTPError & {
  body: { error: string };
};

export const isHTTPErrorWrappedString = (
  response: ErrorResponse
): response is HTTPErrorWrappedString => {
  return (
    isHTTPError(response) &&
    typeof response.body === "object" &&
    "error" in response.body &&
    typeof response.body.error === "string"
  );
};

/**
 * Errors that are returned as RecursiveError objects in the `error` prop
 */
export type HTTPErrorWrappedObject = HTTPError & {
  body: { error: RecursiveErrorObject };
};

export const isHTTPErrorWrappedObject = (
  response: ErrorResponse
): response is HTTPErrorWrappedObject => {
  return (
    isHTTPError(response) &&
    typeof response.body === "object" &&
    "error" in response.body &&
    typeof response.body.error === "object" &&
    !Array.isArray(response.body.error)
  );
};

/**
 * Just in case we also deal with responses that have arrays of error messages
 */
export type LegacyHTTPRawErrorArray = HTTPError & {
  body: string[];
};

export type LegacyHTTPWrappedErrorArray = HTTPError & {
  body: { error: string[] };
};

export const isLegacyHTTPRawErrorArray = (
  response: ErrorResponse
): response is LegacyHTTPRawErrorArray =>
  isHTTPError(response) &&
  !!response.body &&
  typeof response.body === "object" &&
  Array.isArray(response.body);

export const isLegacyHTTPWrappedErrorArray = (
  response: ErrorResponse
): response is LegacyHTTPWrappedErrorArray =>
  isHTTPError(response) &&
  typeof response.body === "object" &&
  "error" in response.body &&
  Array.isArray(response.body.error);

/**
 * The request reached the server, the server returned a successful status code,
 * but an error occurred while parsing the JSON or text response body.
 */
export type PostProcessingError = {
  type: "post";
  error: Error;
  resp: Response;
};

/** One of three different types of errors that can occur when using the Fetch API. */
export type ErrorResponse = NetworkError | HTTPError | PostProcessingError;

/** Determines if and asserts the error is a NetworkError. */
export function isNetworkError(err?: any): err is NetworkError {
  return !!err && err.type === "network";
}

/** Determines if and asserts the error is a PostProcessingError. */
export function isPostProcessingError(err?: any): err is PostProcessingError {
  return !!err && err.type === "post";
}

/**
 * Utility function to transform an array of error messages into a RecursiveErrorObject
 *
 * It will try to split the error message by ":" and use the left part as the key and the right part as the value
 *
 * If it can't split the error message by ":", it will merge all the error messages into a single key called "error"
 */
export const errorMessageArrayToObject = (errors: string[]): RecursiveErrorObject => {
  return errors.reduce<RecursiveErrorObject>((acc, error) => {
    const [field, errorMessage] = error.split(":").map((str) => str.trim());
    if (errorMessage) {
      // Just in case the left side is a sentence, we'll transform it to a camelCase word
      acc[sentenceToCamelCaseWord(field)] = errorMessage;
    } else {
      // if there's no ":" in the error message, we'll add it to the "error" key
      const errorMessage = (acc.error || "").length ? `${acc.error}, ${error}` : error;
      acc.error = errorMessage;
    }
    return acc;
  }, {});
};

/**
 *
 * Function that standardizes any kind of ErrorResponse to RecursiveErrorObject
 *
 */
export const getErrorsAsObject = (response: HTTPError): RecursiveErrorObject => {
  // 🔴 BE CAREFUL: ORDER MATTERS -- MORE SPECIFIC CHECKS SHOULD BE HIGHER UP THE CHAIN
  if (isHTTPErrorWrappedObject(response)) return response.body.error;
  if (isHTTPErrorWrappedString(response)) return errorStringtoErrorObject(response.body.error);
  if (isLegacyHTTPErrorRawObject(response)) return response.body;
  if (isLegacyHTTPErrorRawString(response)) return errorStringtoErrorObject(response.body);
  if (isLegacyHTTPWrappedErrorArray(response))
    return errorMessageArrayToObject(response.body.error);
  if (isLegacyHTTPRawErrorArray(response)) return errorMessageArrayToObject(response.body);
  return {};
};

export const cleanErrorMessage = (errorMessage: string) =>
  `${capitalize(errorMessage.trim().replace(/\.$/, ""))}.`;

/**
 * Recursive function to get any kind of errors inside any kind of ErrorResponse as an array of strings
 *
 * E.g. { one: { two: "error message"}, other: "message", error: "default message" }
 *
 * will produce:
 *
 * ["one.two: error message", "other: message", "default message"]
 *
 * The function will ignore the default `error` key in the object and, if found, will only return the value, skipping the key:
 *
 * { error: "Error Message"} -> ["Error Message"];
 *
 */
export const errorObjectToArray = (
  errors: RecursiveErrorObject, // Possibly recursive error object we're traversing
  fieldPath: string = "" // "level1.level2"
) => {
  const res: string[] = [];
  Object.entries(errors).map(([k, v]) => {
    // for the default "error" error, skip the field name part
    const currField = k === "error" ? "" : k;
    const path = `${fieldPath}.${currField}`;
    // remove leading and trailing dots
    const trimmedPath = path.replace(/^\.|\.$/g, "");
    const sentencePath = dotSeparatedStringToSentence(trimmedPath);
    if (typeof v === "string") {
      const message = `${sentencePath.length ? `${sentencePath}: ` : ""}${cleanErrorMessage(v)}`;
      res.push(message);
    } else {
      res.push(...errorObjectToArray(v, trimmedPath));
    }
  });
  return res;
};

const normalizeErrorMessage = (error: string) => {
  const [errorName, errorMessage] = error.split(":");
  return errorMessage
    ? `${dotSeparatedStringToSentence(errorName).trim()}: ${cleanErrorMessage(errorMessage)}`
    : cleanErrorMessage(errorName);
};

export const normalizeErrorArray = (errorArray: string[]) => errorArray.map(normalizeErrorMessage);

/**
 * Transforms an array of error message strings into a single string
 */
export const errorArrayToString = (errorArray: string[]) =>
  normalizeErrorArray(errorArray).join(" ").trim();

/**
 * Composes the 2 previous functions to go directly from errorObject -> string
 */
export const errorObjectToString = (errorObject: RecursiveErrorObject) =>
  errorArrayToString(errorObjectToArray(errorObject));

/**
 * Unlikely to be needed, but it's here for completeness
 * Transforms a string into an error object
 */
export const errorStringtoErrorObject = (errorString: string) => {
  const [name, message] = errorString.split(":");
  const errorMessage = message ? message.trim() : name;
  const errorName = message ? name : "error";
  return { [errorName]: cleanErrorMessage(errorMessage) };
};

/**
 * Extract the errors from any ErrorResponse into an array of strings
 */
export const getErrorsAsArray = (response: HTTPError): string[] => {
  // if the response already has an array, use it as is w/o unnecessary processing
  if (isLegacyHTTPRawErrorArray(response)) return normalizeErrorArray(response.body);
  if (isLegacyHTTPWrappedErrorArray(response)) return normalizeErrorArray(response.body.error);
  const errors = getErrorsAsObject(response);
  return errorObjectToArray(errors);
};

/**
 * Extract the error(s) from any ErrorResponse in a single string
 */
export const getErrorsAsString = (response: HTTPError): string => {
  const errorArray = getErrorsAsArray(response);
  return errorArrayToString(errorArray);
};

/** Helper generic to easily create API endpoint return types */
export type APIResponse<T> = Promise<
  | [T | undefined, ErrorResponse | undefined]
  | [T | undefined, ErrorResponse | undefined, Response | undefined]
>;

export interface RequestOptions {
  /** The HTTP method to use. Defaults to "GET". */
  method?: string;
  /** The request headers. */
  headers?: Record<string, string>;
  /** The request query parameters. Passed to URLSearchParams constructor. */
  query?: any;
  /** The request body. If you're sending JSON, use the `json` property instead. */
  body?: any;
  /** The JSON request body. The fetch client will automatically stringify it and set the content-type header. */
  json?: any;
}

/**
 * Sends an asynchoronous request to a URL.
 *
 * @param url - Location to send the request to.
 * @param options - Configuration options for the request.
 * @param [fetchFn] - The fetch function to use. Defaults to the global fetch function.
 */
export async function request<T>(
  url: string,
  options?: RequestOptions,
  fetchFn: (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ) => Promise<Response> = window.fetch
): Promise<[T | undefined, ErrorResponse | undefined, Response | undefined]> {
  // Configure the request
  const init: RequestInit = {
    method: options?.method || "GET"
  };
  if (options?.headers) {
    init.headers = options.headers;
  }
  if (options?.body) {
    init.body = options.body;
  }
  if (options?.query) {
    // eslint-disable-next-line no-param-reassign
    url += "?" + new URLSearchParams(options.query).toString();
  }
  if (options?.json) {
    init.body = JSON.stringify(options.json);
    if (init.headers === undefined) init.headers = {};
    (init.headers as Record<string, string>)["content-type"] = "application/json";
  }

  // Make the request
  let resp: Response | null = null;
  try {
    resp = await fetchFn(url, init);
  } catch (err: any) {
    // Network error
    return [undefined, { type: "network", error: err }, undefined];
  }

  // Check for a response body
  let body: any | undefined, readErr: Error | undefined;
  const contentType = (resp.headers.get("content-type") || "").toLowerCase();

  try {
    // Check if the response has a body
    const clonedResp = resp.clone();
    const hasBody = await clonedResp.text().then((text) => text.length > 0);

    if (hasBody) {
      // Read the body if it's JSON or text
      if (isJSON(contentType)) {
        try {
          body = await resp.json();
        } catch (err: any) {
          readErr = err;
        }
      } else if (
        isImage(contentType) ||
        isPDF(contentType) ||
        (url.endsWith("/download") && isText(contentType))
      ) {
        try {
          body = await resp.blob();
        } catch (err: any) {
          readErr = err;
        }
      } else if (isText(contentType)) {
        try {
          body = await resp.text();
        } catch (err: any) {
          readErr = err;
        }
      }
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      readErr = e;
    } else {
      readErr = new Error(`An unknown error occurred: ${e}`);
    }
  }

  // Check for HTTP error
  if (!resp.ok) {
    return [undefined, { type: "http", resp, body }, resp];
  }

  // Check for post-processing error
  if (readErr) {
    return [undefined, { type: "post", resp, error: readErr }, resp];
  }

  // Success!
  return [body, undefined, resp];
}

function isJSON(contentType: string): boolean {
  return contentType.startsWith("application/json");
}

function isText(contentType: string): boolean {
  return contentType.startsWith("text/") || contentType.startsWith("application/xml");
}

function isImage(contentType: string): boolean {
  return contentType.startsWith("image/");
}

function isPDF(contentType: string): boolean {
  return contentType.endsWith("/pdf");
}
