import { create } from "@moovfinancial/common/api/OpenApiClientFactory";

// Had to implement a custom fetch function for 2 reasons:
//
// 1- To be able to use the prism mock server when localStorage.useMockServer is true, as middlewares
// can't overwrite the `url` property of the request object.
//
// 2 - To be able to call silentFetch (TBD)
// const customFetch: typeof globalThis.fetch = async (resource, options) => {
//   if (typeof resource === "string") {
//     return globalThis.fetch(maybeMockUrlWithPrism(resource), options);
//   }
//   if (resource instanceof URL) {
//     const href = maybeMockUrlWithPrism(resource.href);
//     resource.href = href;
//     return globalThis.fetch(resource, options);
//   }
//   if (resource instanceof Request) {
//     const request = new Request(maybeMockUrlWithPrism(resource.url), resource);
//     return globalThis.fetch(request, options);
//   }
//   return globalThis.fetch(resource, options);
// };

export const openApi = create();
